<template>
  <b-container class="my-4 py-4 border-bottom">
    <b-row class="mb-2">
      <b-col>
        <div><strong style="font-size: 1.25em">{{annotation.regulation.regulator.name}}: {{annotation.regulation.name_short}}</strong></div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span style="font-size: 1.25em" v-if="annotation.obligationtype"><b-badge :variant="badgeVariant">{{annotation.obligationtype.name}}</b-badge></span> citation: {{annotation.citation}} | id: {{annotation.id}}
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <div v-if="annotation.topic"><strong>{{annotation.topic}}</strong></div>
        <div><strong style="font-size: 1.25em"><b-link target="_blank" class="mr-4" :to="{ name: 'RegDecompObligation', params: { id: annotation.id }}">{{annotation.name}}</b-link></strong></div>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <div><strong>description</strong></div>
        <div><new-lines :content="annotation.description"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.context">
      <b-col>
        <div><strong>context</strong></div>
        <div><new-lines :content="annotation.context"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.standard">
      <b-col>
        <div><strong>minimum standard</strong></div>
        <div><new-lines :content="annotation.standard"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.lagging">
      <b-col>
        <div><strong>lagging market practice</strong></div>
        <div><new-lines :content="annotation.lagging"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.market_practice">
      <b-col>
        <div><strong>leading market practice</strong></div>
        <div><new-lines :content="annotation.market_practice"></new-lines></div>
      </b-col>
    </b-row>
    <b-row class="mb-2" v-if="annotation.date_effective">
      <b-col>
        <div><strong>effective date</strong></div>
        <div>{{moment(annotation.date_effective).format('YYYY-MM-DD')}}</div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col>
        <div>{{annotation.updated_by}} - {{moment(annotation.updated_at).format('YYYY-MM-DD HH:mm')}}</div>
      </b-col>
      <b-col>
        <div><strong>status:</strong> {{step.name}}</div>
      </b-col>
    </b-row>
    <b-row v-if="showEntities" class="mb-4">
      <b-col>
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="false"
            :labelLinkingField="'entity_id'"
            :linkingModel="'entityobligation'"
            :name="'Entities'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.entities"
            :verticesProp="entities"
          ></select-list-props>
        </div>
      </b-col>
      <b-col>
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="false"
            :labelLinkingField="'area_id'"
            :linkingModel="'areaobligation'"
            :name="'Functions'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.areas"
            :verticesProp="areas"
          ></select-list-props>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col v-if="showRequirements">
        <div>
          <select-list-props
            :editable="false"
            :editAlwaysOn="false"
            :labelLinkingField="'requirement_id'"
            :linkingModel="'obligationrequirement'"
            :name="'Requirements'"
            :parentId="annotation.id"
            :parentLinkingField="'obligation_id'"
            :valuesProp="annotation.requirements"
            :verticesProp="requirements"
          ></select-list-props>
        </div>
      </b-col>
      <b-col v-if="showRequirements">
        <strong>Controls</strong>
        <div v-for="control in annotation.controls" :key="annotation.id + '-control-' + control.id">
          <b-link :to="{ name: 'Control', params: { id: control.id }}">{{control.name}}</b-link>
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col v-if="showRequirements">
        <strong>Risk framework</strong>
        <div v-for="item in annotation.riskcomponents" :key="annotation.id + '-control-' + item.id">
          {{item.name}}
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'
import workflow from '@/workflows/annotation'
import NewLines from '@/components/NewLines.vue'
import SelectListProps from '@/components/sql/SelectListProps.vue'

export default {
  name: 'AnnotationItem',
  props: ['annotation', 'areas', 'entities', 'requirements'],
  components: {
    NewLines,
    SelectListProps
  },
  computed: {
    badgeVariant: function () {
      let res = 'primary'
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          res = 'danger'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'entity') {
          res = 'success'
        }
        if (this.annotation.obligationtype.name.toLowerCase() === 'context') {
          res = 'info'
        }
      }
      return res
    },
    permissionWorkflow: function () {
      let result = false
      const permission = ac.can(this.user.acgroups).readAny('obligationWorkflowApprove').granted
      if (this.step.id >= 7 && permission) {
        result = true
      }
      if (this.step.id < 7) {
        result = true
      }
      return result
    },
    showEntities: function () {
      let show = false
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          show = true
        }
      }
      return show
    },
    showRequirements: function () {
      let show = false
      if (this.annotation.obligationtype) {
        if (this.annotation.obligationtype.name.toLowerCase() === 'obligation') {
          show = true
        }
      }
      return show
    },
    step: function () {
      const step = workflow.find(x => x.id === this.annotation.status_id)
      step.downStep = workflow.find(x => x.id === step.down)
      step.upStep = workflow.find(x => x.id === step.up)
      return step
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
  },
  data () {
    return {
      commentNew: null,
      comments: [],
      moment: moment,
      savingComment: false,
      savingStep: false,
      workflow: workflow
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>
